<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-n2">
      <h4 style="font-weight: 500">Récapitulatif de vos factures</h4>
      <button class="btn btn-primary btn-md px-2" @click="$router.push('/parametres/subscriptions/pricing')">
        Creer un abonnement
      </button>
    </div>
    <hr class="" />
    <div class="card z-depth-1" v-loading="loading">
      <div class="card-body">
        <mdb-tbl responsive >
          <mdb-tbl-head>
            <tr>
              <th>N° Facture</th>
              <th class="text-left">Date</th>
              <th class="text-right">Montant</th>
              <th class="text-right">Nbr Site</th>
              <th class="text-right">Actions</th>
            </tr>
          </mdb-tbl-head>
          <mdb-tbl-body>
            <tr class="tec-table-row" v-for="sub in queriedData" :key='sub.id'>
              <td>{{sub.num_facture_linked}}</td>
              <td class="tec-table-cell text-left"> {{ $moment(sub.created_at).format("YYYY/MM/DD H:m:s") }}</td>
              <td class="tec-table-cell text-right">{{sub.amount | formatNumber}}   </td>
              <td class="tec-table-cell text-right">
                <span v-if="sub.pack_name_slug === 'business-reel'" >illimité</span>
                <span v-else>
                  {{sub.sites.length}}
                </span>
              </td>
              <td class="tec-table-cell text-right">
                <a href="#" @click="exportPdfRecu(sub)" class="btn btn-link btn-sm text-info px-1 py-1 mx-1">Reçu</a>
                <a href="#" @click="exportPdfFN(sub)"  class="btn btn-link btn-sm text-info px-1 py-1 mx-1">Facture</a>
              </td>
            </tr>
          </mdb-tbl-body>
        </mdb-tbl>
         <div class="d-flex justify-content-end">
              <tec-pagination
                v-model="dataPagination.pagination.currentPage"
                :per-page="parseFloat(dataPagination.pagination.perPage)"
                :total="dataPagination.pagination.total"
              ></tec-pagination>
            </div>
      </div>
    </div>
     
  </div>
</template>
<script>
import TecPagination from "@/tba/components/Pagination";
import download from "@/services/download-fetch"

import {
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
} from "mdbvue";
export default {
  name: "sub-setting",

  components: {
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
     TecPagination,
  },
  data() {
    return {
        dataPagination: {
            pagination: {
                perPage:10,
                currentPage: 1,
                perPageOptions: [
                    {text:"10",value:"10", selected: true},
                    {text:"25",value:"25"},
                    {text:"50",value:"50"},
                    {text:"100",value:"100"},
                ],
                total: 0,
            },
            searchQuery: "",
            propsToSearch: [
                "famille",
                "ref",
                "designation",
                "describe",
                "unit",
                "puht",
            ],
        },

        empty:false,
        loading:false,

        data:{
            columns: [],
            rows:[]
        },
    };
  },
  methods: {
    closeMSub() {
      this.modalSub.show = false;
      this.loadSites();
    },

    ShowMSub() {
      this.modalSub.show = true;
    },
    
    async loadSites() {
      this.$nprogress.start();

      await this.$store
        .dispatch("site/findAll")
        .then((response) => {
          this.$nprogress.done();
          this.sites = response.data.sites.filter((site) => site.is_subscribed === 0);

          this.sites.forEach((site) => {
            this.siteOption.push({
              text: site.name,
              value: site.id,
            });
          });
        })
        .catch((error) => {
          this.$nprogress.done();

          this.$notify({
              
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    getSite(value) {
      this.form.sites = value;
    },
    async validatePayment() {
      if (this.form.sites.length <= 0) {
        this.$notify({
          message: "Vous devez sélectionner un site au moins.",
          type: "warning",
        });

        return;
      }
      this.form.amount = this.getNbrSiteSelected * this.getPerAn;

      window.openKkiapayWidget({
        amount: this.form.amount,
        position: "right",
        callback: "",
        data: "RTEC",
        sandbox: false,
        theme: "#1A2980",
        key: "df0f4343afe82dc924ebc5a734c9afb006201a41",
      });

      window.addSuccessListener((response) => {
        this.form.transactionId = response.transactionId;
        this.form.account = response.account;

        this.createSub();
      });
    },
    async createSub() {
      this.$nprogress.start();
      await this.$store
        .dispatch("subscription/save", this.form)
        .then((response) => {
          this.$nprogress.done();
          this.closeMSub();
          this.$notify({
                
            message: response.data.message,
            type: "success",
          });
        })
        .catch((error) => {
          this.$nprogress.done();
          this.$notify({
              
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    // 
    async loadSubscritions() {
      this.$nprogress.start();
      this.loading = !this.loading
      await this.$store
        .dispatch("subscription/findAll")
        .then((response) => {
          this.loading = !this.loading

          this.$nprogress.done();
          this.data.rows = response.data.subscriptions
        })
        .catch((error) => {
          this.$nprogress.done();
          this.loading = !this.loading

          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async exportPdfRecu(sub){
        await download({
            url:'/subscriptions/'+sub.id+'?r=1',
            method:"get",
            data:[],
            isTh:0,
            filename:'r-'+sub.num_facture_linked.toLowerCase()+'.pdf'
        }).then(() => {
            this.$notify({
                 
                message: "PDF Généré avec succès",
                type: 'success'
            })
        })
        .catch(() => {
            this.$notify({
                   
                message: "Echec durant l'exportation, reessayer ulterieurement",
                type: 'danger'
            })
        })
    },

    async exportPdfFN(sub){
        await download({
            url:'/subscriptions/'+sub.id+'?f=1',
            method:"get",
            data:[],
            isTh:0,
            filename:'f-'+sub.num_facture_linked.toLowerCase()+'.pdf'
        }).then(() => {
            this.$notify({
                 
                message: "PDF Généré avec succès",
                type: 'success'
            })
        })
        .catch(() => {
            this.$notify({
                   
                message: "La facture normalisée n'est pas encore prête.",
                type: 'danger'
            })
        })
    },

    // Systeme de datatable
    totalCalcul () {
        return this.dataPagination.pagination.total = this.data.rows.length;
    },

    searchQ () {
        if (!this.dataPagination.searchQuery) {
            // return this.pagedData;
            this.totalCalcul()
            return new Error('Nothing To Search')
        }
        
        let result = this.data.rows.filter((row) => {
            let isIncluded = false;
            for (let key of this.dataPagination.propsToSearch) {
                let rowValue = "";

                if(row[key] !== undefined && row[key] !== null){
                    rowValue = row[key].toString().toLowerCase();
                }
                if (rowValue.includes && rowValue.includes(this.dataPagination.searchQuery.toLowerCase())) {
                isIncluded = true;
                }
            }
            return isIncluded;
        });
        this.dataPagination.pagination.total = result.length;
        return result.slice(this.from, this.to);
    },

    nbrPerPageSelected(value){
        let nbr = value;
        
        return this.nbrPerPage(nbr)
    },

    nbrPerPage (nbr) {
        return this.dataPagination.pagination.perPage = nbr
    },
  },
  created() {
    this.loadSubscritions();  
  },
  computed: {
    pageData () {
      return this.data.rows.slice(this.from, this.to);
    },

    to() {
      let highBound = this.from + this.dataPagination.pagination.perPage;
      if (this.total < highBound) {
          highBound = this.total;
      }
      return highBound;
    },

    queriedData() {
      let result = this.searchQ();
      return (result instanceof Error) ? this.pageData : result;
    },

    from() {
      return this.dataPagination.pagination.perPage * (this.dataPagination.pagination.currentPage - 1);
    },

    total() { 
      return this.totalCalcul();
    },
  },
  // mounted() {
  //   this.$loadScript("https://cdn.kkiapay.me/k.js");
  // },
  // beforeDestroy() {
  //   window.location.reload()
  //   this.$unloadScript("https://cdn.kkiapay.me/k.js");
  // },
};
</script>

<style lang="scss" scoped> 
.kkp {
  background-color: rgb(0, 110, 255) !important;

  &.hover {
    background-color: rgb(0, 110, 255) !important;

    border: 1px solid rgb(0, 110, 255);
  }
}


</style>
<style >
  .list-group a:hover, .list-group button:hover {
      transition: .1s;
}
</style>